<script setup lang="ts">
// Components
import { useI18n } from 'vue-i18n';

// Composables
const { t } = useI18n();

// Data
const tabs: { label: string; to: string }[] = [
  {
    label: t('global.alert_configuration'),
    to: '/configuration/alerts',
  },
  {
    label: t('global.production times'),
    to: '/configuration/production-hours',
  },
  {
    label: t('global.contracts'),
    to: '/configuration/contracts',
  },
  {
    label: t('global.areas'),
    to: '/configuration/areas',
  },
];
</script>

<template>
  <ui-page :title="t('global.configurations')" :tabs="tabs" />
</template>
